import { type AuthService } from '../auth-service'
import { AuthorizationHelper } from './authorization-helper'
import { AuthorizationByFeature } from './authorization/authorization-by-feature'

export async function getAuthorizationHelperInstance(authService: AuthService) {
  const authByFeature = new AuthorizationByFeature(authService)
  await authByFeature.loadFeatures()

  return AuthorizationHelper.Instance(authByFeature)
}
