import { AuthService } from './auth-service'
import { MsalProvider } from './msal-provider'
import { type IAuthorizationHelper } from './authorization-helper/authorization-helper.types'
import { type IAuthService } from './auth-service/auth-service.types'
import { getAuthorizationHelperInstance } from './authorization-helper/authorization-helper-factory'
import { noticeError } from './utils'
import { InitServicesError } from './auth-service/auth-service.error'

const msalProvider = MsalProvider.makeInstance()
const authService = AuthService.makeInstance(msalProvider)
const authorizationService: AuthorizationService = { init }
interface AuthorizationServiceInstances {
  authorizationHelper: IAuthorizationHelper
  authService: IAuthService
}
interface AuthorizationService {
  init: () => Promise<AuthorizationServiceInstances | undefined>
  authorizationHelper?: IAuthorizationHelper
}

async function init(): Promise<AuthorizationServiceInstances | undefined> {
  try {
    await authService.signIn()

    const authorizationHelper = await getAuthorizationHelperInstance(authService)
    authorizationService.authorizationHelper = authorizationHelper
    return { authorizationHelper, authService }
  } catch (error) {
    noticeError(new InitServicesError(error as Error), { id: authService.userData?.id })
    authService.signOut()

    return undefined
  }
}

export { authorizationService, authService }
