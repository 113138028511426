import { AuthServiceErrorTypes } from './auth-service.types'

export class AuthServiceError extends Error {
  constructor(err: Error = { name: AuthServiceErrorTypes.UnknownError, message: '' }) {
    super(err.message)
    this.stack = err.stack ?? this.stack
    this.name = err.name
  }
}

export class SignInError extends AuthServiceError {
  constructor(err: Error) {
    err.name = AuthServiceErrorTypes.SignInError
    super(err)
  }
}

export class SignOutError extends AuthServiceError {
  constructor(err: Error) {
    err.name = AuthServiceErrorTypes.SignOutError
    super(err)
  }
}

export class ExpiredTokenError extends AuthServiceError {
  constructor(err: Error) {
    err.name = AuthServiceErrorTypes.ExpiredTokenError
    super(err)
  }
}

export class ParseUserError extends AuthServiceError {
  constructor(err: Error) {
    err.name = AuthServiceErrorTypes.ParseUserError
    super(err)
  }
}

export class InitServicesError extends AuthServiceError {
  constructor(err: Error) {
    err.name = AuthServiceErrorTypes.InitServicesError
    super(err)
  }
}
