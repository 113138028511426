import { noticeError } from '../utils'

import { ExpiredTokenError, ParseUserError, SignInError, SignOutError } from './auth-service.error'
import type { IAuthProvider, IAuthService, TokenClaims } from './auth-service.types'
import type { UserData } from '../shared/types'

export class AuthService implements IAuthService {
  userData: UserData | undefined

  constructor(private readonly authProvider: IAuthProvider) {}

  public async signIn() {
    try {
      const isAuth = await this.authProvider.isAuthenticated()

      if (isAuth) {
        this.userData = this.parseUser(this.authProvider.authResultClaims)
        Object.freeze(this)
      } else {
        await this.authProvider.redirectToLogin()
      }
    } catch (err) {
      const error = new SignInError(err as Error)
      noticeError(error)
      throw error
    }
  }

  public async signOut() {
    try {
      await this.authProvider.redirectToLogout()
    } catch (err) {
      noticeError(new SignOutError(err as Error))
    }
  }

  public async getTokenOrRedirect() {
    try {
      const token = await this.authProvider.getTokenAsync()
      if (token !== null) {
        return token
      }
    } catch (err) {
      noticeError(new ExpiredTokenError(err as Error))
    }

    /**
     * Caso ocorra qualquer tipo de rro na renovação to token, redirecionamos para tela de login
     */
    await this.authProvider.getTokenAsyncRedirect()

    /**
     * Esta chamada nunca deveria acontecer pois a chamada acima redireciona o navegador para a tela de login
     * Está aqui apenas para manter a assinatura da função de "never"
     */
    throw new Error('Failed to renew token')
  }

  private parseUser(claims: TokenClaims): UserData | undefined {
    try {
      // @ts-expect-error When string failed to be parsed as JSON, an error will throw
      return JSON.parse(claims.extension_user)
    } catch (err) {
      // Log out forcefully if my token does not have the 'extension_user' property.
      this.signOut()

      noticeError(new ParseUserError(err as Error))
    }
  }

  static makeInstance(authProvider: IAuthProvider) {
    return new AuthService(authProvider)
  }
}
