import type { IAuthorizationHelper } from './authorization-helper.types'
import { type CanAccessProps, type IAuthorization } from './authorization/authorization.types'
export class AuthorizationHelper implements IAuthorizationHelper {
  private static _instance: AuthorizationHelper

  private constructor(private readonly authorization: IAuthorization) {}

  public static Instance(authorization: IAuthorization) {
    return (this._instance ??= new this(authorization))
  }

  public canAccessFeature({ featureName, journeyName }: CanAccessProps) {
    return this.authorization.canAccess({ featureName, journeyName })
  }
}
