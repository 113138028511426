import { HttpClient } from '@grupoboticario/vdi-mfe-utils'
import { authService } from '../vdi-auth-service'
import { env } from './environment'

const getHeaders = async (): Promise<Record<string, string>> => {
  const { token } = await authService.getTokenOrRedirect()
  return {
    authorization: `Bearer ${token}`,
    'content-type': 'application/json'
  }
}

export const http = HttpClient.create({
  baseUrl: env.USERS_API_URL,
  timeout: 30000,
  getHeaders
})
