import { type User } from 'configcat-js'
import { authService } from '../vdi-auth-service'
import { configCatClient } from './config-cat'

export enum Flags {
  SAMPLE_FLAG = 'sampleFlag'
}

export type FlagData = {
  [key in Flags]: boolean
}

const flagsDefaultValues: FlagData = {
  [Flags.SAMPLE_FLAG]: false
}

export const flags: FlagData = {
  [Flags.SAMPLE_FLAG]: flagsDefaultValues[Flags.SAMPLE_FLAG]
}

export async function loadFlags() {
  const user: User = {
    identifier: authService.userData?.id ?? '',
    email: authService.userData?.email,
    custom: {}
  }

  const sampleFlag = await configCatClient.getValueAsync(
    Flags.SAMPLE_FLAG,
    flagsDefaultValues[Flags.SAMPLE_FLAG],
    user
  )

  flags[Flags.SAMPLE_FLAG] = sampleFlag
}
