import { type UserData } from '../shared/types'

export interface IAuthService {
  readonly signIn: () => Promise<void>
  readonly signOut: () => Promise<void>
  readonly getTokenOrRedirect: () => Promise<TokenResult | null>
  readonly userData: UserData | undefined
}

export interface IAuthProvider {
  isAuthenticated: () => Promise<boolean>
  redirectToLogin: () => Promise<void>
  redirectToLogout: () => Promise<void>
  getTokenAsync: () => Promise<TokenResult | null>
  getTokenAsyncRedirect: () => Promise<void>
  readonly userLang: string
  readonly authResultClaims: TokenClaims
}

export interface TokenResult {
  token: string
  claims: TokenClaims
}

export interface TokenClaims {
  extension_user?: string
}

export enum AuthServiceErrorTypes {
  SignInError = 'SignInError',
  SignOutError = 'SignOutError',
  ExpiredTokenError = 'ExpiredTokenError',
  UnknownError = 'UnknownError',
  ParseUserError = 'ParseUserError',
  InitServicesError = 'InitServicesError'
}
