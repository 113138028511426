import * as configCat from 'configcat-js'
import { env } from './environment'

const isDevelopment = process.env.NODE_ENV === 'development'

const options = {
  cacheTimeToLiveSeconds: 300,
  logger: isDevelopment ? configCat.createConsoleLogger(configCat.LogLevel.Info) : null
}

export const configCatClient = configCat.getClient(
  env.CONFIG_CAT_KEY,
  configCat.PollingMode.LazyLoad,
  options
)
